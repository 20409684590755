import * as path from 'path';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetweenjs from 'dayjs/plugin/isBetween';
import { CommonSettings } from './common-settings';
import { nanoid } from 'nanoid';
import { Table } from '@/store/models/table';
import { LineUserLog, OrderData } from '@/store/models/db/r-tables';
import {
  DiscountDetail,
  ImageObject,
  languageType,
  Multilingual,
  OrderItem,
  OrderSubMenu,
  OrderSubMenuItem,
  Payment,
  ReceiptJournalItem,
  UsableCoupon,
} from '@/store/models/db/common-models';
import { OrderModel } from '@/store/models/order';
import { Menu } from '@/store/models/menu';
import { FullMetadata, deleteObject, getMetadata, uploadBytes } from 'firebase/storage';
import { storageRef } from '@/firebase/dao';
import { StoreError } from './error';
import { CustomerInfo } from '@/store/models/db/r-ec-orders';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { MessageResourceKeys, messageFunction, messageResource } from '@/resources/messages';
import { awardType, paymentStatus, Permission, salesType, taxRoundingType, transactionType } from './appCode';
import { useSmsAuthStore } from '@/stores/sms-auth';
import { MembersRank } from '@/store/models/members-rank';
import { Timestamp } from 'firebase/firestore';
import { Settings } from '@/store/models/settings';
import { TranslateLanguage, TranslatedContents } from '@/firebase/dto/translate';
import { AccountSetting } from '@/store/models/account-setting';
import { RequestChoices } from '@/store/models/db/r-settings';
import { DeliveryFeesByPostalCode } from '@/store/models/delivery-fees-by-postal-code';

dayjs.locale('ja');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetweenjs);

export const numberFormatByYen = (num: number): string => `¥${num.toLocaleString()}`;

export const timeFormatByColon = (date: Date | string): string => dayjs(date).format('HH:mm');

export const dateFormatFromMonth = (date: Date | string): string => dayjs(date).format('MM/DD(ddd)');

export const dateHourFormatFromMonth = (date: Date | string): string => dayjs(date).format('M/D(ddd) HH');

export const dateTimeFormatFromMonth = (date: Date | string): string => dayjs(date).format('M/D(ddd) HH:mm');

export const dateTimeFormatFromMonthWithoutDay = (date: Date | string): string => dayjs(date).format('M/D HH:mm');

export const dateFormatBySlash = (date: Date | string): string => dayjs(date).format('YYYY/MM/DD');

export const dateMonthFormatBySlash = (date: Date | string): string => dayjs(date).format('YYYY/MM');

export const dateFormatByHyphen = (date: Date | string): string => dayjs(date).format('YYYY-MM-DD');

export const dateMonthFormatByHyphen = (date: Date | string): string => dayjs(date).format('YYYY-MM');

export const dateTimeFormatBySlash = (date: Date | string): string => dayjs(date).format('YYYY/MM/DD HH:mm');

export const dateTimeFormatByHyphen = (date: Date | string): string => dayjs(date).format('YYYY-MM-DD HH:mm');

export const dateTimeFormatJapanese = (date: Date | string): string => dayjs(date).format('M月D日(ddd) HH:mm');

export const dateFormatJapanese = (date: Date | string): string => dayjs(date).format('YYYY年MM月DD日');

export const dateFormatJapaneseWithWeekDay = (date: Date | string): string => dayjs(date).format('YYYY年M月D日(ddd)');

export const dateHourFormatJapaneseWithWeekDay = (date: Date | string): string =>
  dayjs(date).format('YYYY年M月D日(ddd) HH');

export const dateTimeFormatJapaneseWithWeekDay = (date: Date | string): string =>
  dayjs(date).format('YYYY年M月D日(ddd) HH:mm');

export const dateFormatJapaneseYMD = (date: Date | string): string => dayjs(date).format('YYYY年M月D日');

export const dateFormatJapaneseMonthDay = (date: Date | string): string => dayjs(date).format('M月D日(ddd)');

export const dateFormatJapaneseYearMonth = (date: Date | string): string => dayjs(date).format('YYYY年M月');

export const dateTimeFormatYYYYMMDDTHHmmssSSS = (date: Date | string): string =>
  dayjs(date).format('YYYYMMDDHHmmssSSS');

export const dateTimeFormatYYYYMM = (date: Date | string): string => dayjs(date).format('YYYYMM');

export const dateTimeFormatYYYYMMDD = (date: Date | string): string => dayjs(date).format('YYYYMMDD');

export const timeFormat = (date: Date | string): string => dayjs(date).format('HH:mm:ss.SSS').slice(0, -2);

export const timeFormatHH = (date: Date | string): string => dayjs(date).format('HH');

export const timeFormatHHmm = (date: Date | string): string => dayjs(date).format('HH:mm');

export const dateFormatM = (date: Date | string): string => dayjs(date).format('M');

export const dateTimeFormatDD = (date: Date | string): string => dayjs(date).format('DD');

export const dateFormatDateTimePicker = (date: Date): string => dayjs(date).format('YYYY-MM-DDTHH:mm');

export const getDay = (date: Date | string): string => dayjs(date).format('ddd');

export const getHour = (date: Date | string): string => dayjs(date).format('HH');

export const getFirstDate = (target: Date): Date => dayjs(target).date(1).toDate();

export const getEndDate = (target: Date): Date => dayjs(target).endOf('month').toDate();

export const addDays = (target: Date | string, addDay: number): Date => dayjs(target).add(addDay, 'day').toDate();

export const subtractDay = (target: Date | string, subtractDay: number): Date =>
  dayjs(target).subtract(subtractDay, 'day').toDate();

export const addMonths = (target: Date | string, addMonths: number): Date =>
  dayjs(target).add(addMonths, 'months').toDate();

export const addHours = (target: Date | string, addHours: number): Date =>
  dayjs(target).add(addHours, 'hours').toDate();

export const addMinutes = (target: Date | string, addMinutes: number): Date =>
  dayjs(target).add(addMinutes, 'minutes').toDate();

export const getDiffSeconds = (from: Date | string, to: Date | string): number =>
  dayjs(to).diff(dayjs(from), 'seconds');

export const getDiffMinutes = (from: Date | string, to: Date | string): number =>
  dayjs(to).diff(dayjs(from), 'minutes');

export const getDiffDays = (from: Date | string, to: Date | string): number => dayjs(to).diff(dayjs(from), 'days');

export const isDateSame = (a: Date | string, b: Date | string): boolean => dayjs(a).isSame(b);

export const isDateSameOrAfter = (a: Date | string, b: Date | string): boolean => dayjs(a).isSameOrAfter(b);

export const isDateAfter = (a: Date | string, b: Date | string): boolean => dayjs(a).isAfter(b);

export const isDateSameOrBefore = (a: Date | string, b: Date | string): boolean => dayjs(a).isSameOrBefore(b);

export const isBefore = (a: Date | string, b: Date | string): boolean => dayjs(a).isBefore(b);

/**
 * 比較対象日付がfrom, toの間にあるかどうか
 *
 * @param a 比較対象日付
 * @param from 比較日付（から）
 * @param to 比較日付（まで）
 * @param compare 比較方法（
 *  (): from < a < to
 *  [): from <= a < to
 *  (]: from < a <= to
 *  []: from <= a <= to
 * ）
 */
export const isBetween = (
  a: Date | string,
  from: Date | string,
  to: Date | string,
  compare: '()' | '[)' | '(]' | '[]' = '[]'
): boolean =>
  // 同日時を含む
  dayjs(a).isBetween(from, to, undefined, compare);

export const parseDate = (dateStr: string): Date => dayjs(dateStr).toDate();

export const toBoolean = (val: string): boolean => val?.toLowerCase() === 'true' ?? false;

/**
 * 指定された時間文字列（'xx:xx'形式）と日付オブジェクトを組み合わせて、
 * 新しいDateオブジェクトを返します。
 *
 * @param {string} time - 'HH:mm'形式の時間文字列（例: '09:59'）。
 * @param {Date} date - 日付を表すDateオブジェクト。
 * @returns {Date} 指定された時間を持つ新しいDateオブジェクト。
 */
export const createDateWithTime = (time: string, date: Date): Date => {
  // 日付部分を YYYY-MM-DD 形式で取得
  const todayDate = date.toISOString().split('T')[0]; // ISO 8601形式の 'YYYY-MM-DD' を取得

  // フォーマットされた日時文字列を作成
  const dateTimeString = `${todayDate}T${time}:00`; // 'YYYY-MM-DDTxx:xx:00'

  // 文字列を Date 型に変換して返す
  return new Date(dateTimeString);
};

/**
 * 最短受取時間を取得する
 *
 * @param dayBefore x日前
 * @param deadlineTime 受付締切時間
 * @param openTimeStr 受取開始時間
 */
export const getEarliestPickUpTimeForPreOrder = (dayBefore: number, deadlineTime: string, openTimeStr = ''): Date => {
  const now = new Date();

  const deadlineToday: dayjs.Dayjs = dayjs(`${dateTimeFormatYYYYMMDD(now)}${deadlineTime}`, 'YYYYMMDDHH:mm').second(0);
  const openTimeToday: dayjs.Dayjs = dayjs(dateTimeFormatYYYYMMDD(now) + openTimeStr, 'YYYYMMDDHH:mm').second(0);

  const result =
    now < deadlineToday.toDate()
      ? openTimeToday.add(dayBefore, 'day').toDate()
      : openTimeToday.add(dayBefore + 1, 'day').toDate();

  return result;
};

/**
 * 日付と時間を結合してDateを取得
 * @param targetDate
 * @param targetTime
 * @returns 日付を作成
 */
export const getDateFromString = (targetDate: string, targetTime: string): Date =>
  dayjs(targetDate + ' ' + targetTime, 'YYYY-MM-DD HH:mm')
    .second(0)
    .toDate();

export const isEmpty = (val: unknown): boolean => !val && !(val === 0 || val === false);

export const maxLength = (str: string, max: number): boolean => str.length <= max;

export const minLength = (str: string, min: number): boolean => str.length >= min;

export const sum = (numbers: number[], initialValue = 0): number =>
  numbers.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);

export const average = (numbers: number[]): number => Math.floor(sum(numbers) / numbers.length);

export const isInteger = (x: unknown): x is number =>
  typeof x === 'number' && new RegExp(/^[+,-]?([1-9]\d*|0)$/).test(String(x));

export const isFloat = (x: unknown): boolean =>
  typeof x === 'number' && new RegExp(/^[+,-]?([1-9]\d*|0|0.\d+)$/).test(String(x));

export const isWithinDigits = (number: number, maxIntegerDigits: number, maxDecimalDigits?: number): boolean => {
  const [integerPart, decimalPart] = number.toString().split('.');
  const isIntegerWithinLimit = integerPart.length <= maxIntegerDigits;
  // 小数部がundefinedか、maxDecimalDigitsがundefinedの場合、true
  // そうでなければ、小数部の桁数をチェック
  const isDecimalWithinLimit =
    maxDecimalDigits === undefined || decimalPart === undefined || decimalPart.length <= maxDecimalDigits;

  return isIntegerWithinLimit && isDecimalWithinLimit;
};

export const isEmoji = (x: unknown): boolean => /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(String(x));

export const isKatakana = (x: unknown): boolean => /^[ァ-ヶー]+$/.test(String(x));

export const isEmailAddress = (x: unknown): boolean =>
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(
    String(x)
  );

/** 電話番号パターンチェック（ハイフン抜き） */
export const isPhoneNumber = (x: unknown): boolean => /^(0\d{9,10})$/.test(String(x));

/** 携帯電話番号パターンチェック（ハイフン抜き） */
export const isMobilePhoneNumber = (x: unknown): boolean => /^(0[789]0\d{8})$/.test(String(x));

export const isPostCode = (x: unknown): boolean => new RegExp(/^[0-9]{7}$/).test(String(x));

export const tableNameCharValidation = (val: string): boolean =>
  val.indexOf('&') === -1 &&
  val.indexOf(',') === -1 &&
  val.indexOf('"') === -1 &&
  val.indexOf("'") === -1 &&
  val.indexOf('<') === -1 &&
  val.indexOf('>') === -1 &&
  val.indexOf('/') === -1 &&
  val.indexOf('?') === -1 &&
  val.indexOf(' ') === -1 &&
  val.indexOf('`') === -1;

export const menuNameCharValidation = (val: string): boolean =>
  val.indexOf('&') === -1 &&
  val.indexOf(',') === -1 &&
  val.indexOf('"') === -1 &&
  val.indexOf("'") === -1 &&
  val.indexOf('<') === -1 &&
  val.indexOf('>') === -1 &&
  val.indexOf('/') === -1 &&
  val.indexOf('?') === -1 &&
  val.indexOf('`') === -1;

export const getImagePath = (url: string): string => path.join(path.dirname(url), `${url.split('/').pop()}`);

export const getBusinessDate = (entryDate: Date, opneTimeStr: string): Date => {
  const businessDate: dayjs.Dayjs = dayjs(dateTimeFormatYYYYMMDD(entryDate), 'YYYYMMDD');

  const opneTime: Date = dayjs(dateTimeFormatYYYYMMDD(entryDate) + opneTimeStr, 'YYYYMMDDHH:mm')
    .second(0)
    .toDate();

  const isEarlierThanXHoursBeforeOpenTime = entryDate < opneTime;

  const result = isEarlierThanXHoursBeforeOpenTime ? businessDate.subtract(1, 'day').toDate() : businessDate.toDate();

  return result;
};

/**
 * 指定日の開店日時と閉店日時を取得する
 *
 * @param entryDate
 * @param openTimeStr
 */
export const getOpenCloseTime = (
  target: Date,
  openTimeStr: string,
  closeTimeStr: string
): { openTime: Date; closeTime: Date } => {
  const openTime: Date = dayjs('20190101' + openTimeStr, 'YYYYMMDDHH:mm')
    .second(0)
    .toDate();
  const closeTime: Date = dayjs('20190101' + closeTimeStr, 'YYYYMMDDHH:mm')
    .second(0)
    .toDate();

  const closeTimeIsNextDay = closeTime.getTime() < openTime.getTime();

  const openTimeRes: dayjs.Dayjs = dayjs(dateTimeFormatYYYYMMDD(target) + openTimeStr, 'YYYYMMDDHH:mm').second(0);
  const closeTimeRes: dayjs.Dayjs = dayjs(dateTimeFormatYYYYMMDD(target) + closeTimeStr, 'YYYYMMDDHH:mm').second(0);

  const result = {
    openTime: openTimeRes.toDate(),
    closeTime: closeTimeIsNextDay ? closeTimeRes.add(1, 'day').toDate() : closeTimeRes.toDate(),
  };

  return result;
};

/**
 * 指定日時データの月の営業期間を取得する
 *
 * @param entryDate
 * @param opneTimeStr
 */
export const getBusinessMonth = (target: Date, opneTimeStr: string): { from: Date; to: Date } => {
  const opneTimeRes: dayjs.Dayjs = dayjs(dateTimeFormatYYYYMMDD(target) + opneTimeStr, 'YYYYMMDDHH:mm').second(0);

  const result = {
    from: opneTimeRes.toDate(),
    to: opneTimeRes.add(1, 'month').subtract(1, 'second').toDate(),
  };

  return result;
};

export const getColorSchemeClassicBlueRed6 = (): string[] => [
  '#2c69b0',
  '#f02720',
  '#ac613c',
  '#6ba3d6',
  '#ea6b73',
  '#e9c39b',
];

export const getColorSchemeRdylgn11 = (): string[] => [
  '#a50026',
  '#d73027',
  '#f46d43',
  '#fdae61',
  '#fee08b',
  '#ffffbf',
  '#d9ef8b',
  '#a6d96a',
  '#66bd63',
  '#1a9850',
  '#006837',
];

export const getColorSchemeTableau20 = (): string[] => [
  '#4E79A7',
  '#A0CBE8',
  '#F28E2B',
  '#FFBE7D',
  '#59A14F',
  '#8CD17D',
  '#B6992D',
  '#F1CE63',
  '#499894',
  '#86BCB6',
  '#E15759',
  '#FF9D9A',
  '#79706E',
  '#BAB0AC',
  '#D37295',
  '#FABFD2',
  '#B07AA1',
  '#D4A6C8',
  '#9D7660',
  '#D7B5A6',
];

/**
 * ストレージに登録するファイルパスを取得
 * @param obj
 * @returns
 */
export const getStorageFilePath = (obj: {
  is2ndGenData: boolean;
  folderName: string;
  contractId: string;
  storeId: string;
  fileName: string;
}): string => {
  const timestamp = new Date().getTime();
  return obj.is2ndGenData
    ? `${obj.folderName}/${obj.contractId}/${obj.storeId}/${timestamp}-${obj.fileName}`
    : `${obj.folderName}/${obj.contractId}/${timestamp}-${obj.fileName}`;
};

/**
 * ストレージ内のファイルのメタデータを取得
 * @param obj
 * @returns
 */
export const getMediaMetaData = async (obj: { url: string }): Promise<FullMetadata> => {
  try {
    return await getMetadata(storageRef(obj.url));
  } catch (error) {
    throw new StoreError(error.name, error.message);
  }
};

/**
 * メニュー画像の登録
 *
 * @param obj
 */
export const setMenuImage = async (obj: { url: string; imageFile: File }): Promise<void> => {
  try {
    const metaData = {
      contentType: obj.imageFile.type,
    };
    // ファイルのアップロード処理
    await uploadBytes(storageRef(obj.url), obj.imageFile, metaData);
  } catch (error) {
    throw new StoreError(error.name, error.message);
  }
};

/**
 * メニュー画像の削除
 *
 * @param obj
 */
export const deleteImage = async (obj: { url: string }): Promise<void> => {
  try {
    await deleteObject(storageRef(obj.url));
  } catch (error) {
    throw new StoreError(error.name, error.message);
  }
};

/**
 * 多言語化の表示内容を返す
 * @param obj
 * @returns
 */
export const getMultilingualLabel = (obj: {
  useMultilingual: boolean;
  language: string;
  defaultLabel: string;
  items?: Multilingual;
}): string => {
  if (obj.useMultilingual) {
    return obj.items && obj.items[obj.language] ? obj.items[obj.language] : obj.defaultLabel;
  } else {
    return obj.defaultLabel;
  }
};

/**
 * 自動翻訳APIに渡すパラメータを取得
 * @param obj
 * @returns
 */
export const getTranslateParams = (obj: {
  text: string;
  translatedData: Multilingual;
  useLanguage: TranslateLanguage;
}): {
  text: string;
  translateLanguage: TranslateLanguage;
} | null => {
  // 翻訳元のテキストがない場合は翻訳しない
  if (!obj.text) return null;

  // 全言語が翻訳済みの場合は翻訳しない
  if (
    (!obj.useLanguage.en || (obj.useLanguage.en && obj.translatedData.en)) &&
    (!obj.useLanguage.zh || (obj.useLanguage.zh && obj.translatedData.zh)) &&
    (!obj.useLanguage.ko || (obj.useLanguage.ko && obj.translatedData.ko))
  ) {
    return null;
  }

  return {
    text: obj.text,
    translateLanguage: {
      en: obj.useLanguage.en && !obj.translatedData.en,
      zh: obj.useLanguage.zh && !obj.translatedData.zh,
      ko: obj.useLanguage.ko && !obj.translatedData.ko,
    },
  };
};

/**
 * 翻訳内容をセットして返す
 * @param obj
 * @returns
 */
export const setTranslatedValue = (
  source: Multilingual,
  translated: TranslatedContents,
  isSetJapanese: boolean = true
): Multilingual => {
  source.ja = isSetJapanese ? translated.ja : '';
  source.en = translated.en.length > 0 ? translated.en : source.en;
  source.zh = translated.zh.length > 0 ? translated.zh : source.zh;
  source.ko = translated.ko.length > 0 ? translated.ko : source.ko;

  return source;
};

/**
 * 静的な表示文言の内容を返す
 * @param obj
 * @returns
 */
export const getStaticLabel = (obj: { language: string; labelName: MessageResourceKeys }): string => {
  return messageResource[obj.labelName] && messageResource[obj.labelName][obj.language]
    ? messageResource[obj.labelName][obj.language]
    : '';
};

/**
 * SELECTED_ITEMの内容を返す
 * @param obj
 * @returns
 */
export const getSelectedItemMessage = (obj: {
  useMultilingual: boolean;
  language: string;
  title: string;
  submenu?: Multilingual;
}): string => {
  return (
    (messageFunction.SELECTED_ITEM(
      getMultilingualLabel({
        useMultilingual: obj.useMultilingual,
        language: obj.language,
        defaultLabel: obj.title,
        items: obj.submenu,
      })
    )[obj.language] as string) ?? ''
  );
};

/**
 * 順番待ちの「希望条件の選択項目」を表示言語に合わせて取得
 */
export const getRequest1Choices = (obj: {
  isUseMultilingual: boolean;
  useLanguage: string;
  request1Choises?: RequestChoices[];
}): RequestChoices[] => {
  if (!obj.isUseMultilingual || !obj.request1Choises) {
    return obj.request1Choises ?? [];
  }

  if (obj.useLanguage === 'ja') {
    return obj.request1Choises.map((val) => ({ ...val, name: val.multilingualName?.ja || val.name }));
  } else if (obj.useLanguage === 'en') {
    return obj.request1Choises.map((val) => ({ ...val, name: val.multilingualName?.en || val.name }));
  } else if (obj.useLanguage === 'zh') {
    return obj.request1Choises.map((val) => ({ ...val, name: val.multilingualName?.zh || val.name }));
  } else if (obj.useLanguage === 'ko') {
    return obj.request1Choises.map((val) => ({ ...val, name: val.multilingualName?.ko || val.name }));
  }

  return obj.request1Choises;
};

/**
 * 順番待ちの「希望条件の選択項目」の選択出来るかを判定
 * @param item
 * @param total
 * @returns
 */
export const disabledRequestChoises = (item: RequestChoices, total: number): boolean => {
  const min = !item.minPeople || item.minPeople === 0 ? 1 : item.minPeople;
  const max = !item.maxPeople || item.maxPeople === 0 ? 99 : item.maxPeople;

  return total < min || total > max;
};

/**
 * 順番待ちの「希望条件の選択項目」の選択できない項目を押下した時にアラート表示
 * @param language
 * @param disabled
 * @param min
 * @param max
 * @returns
 */
export const alertRequestChoiseMessage = (language: string, disabled: boolean, min?: number, max?: number): void => {
  if (!disabled) {
    return;
  }

  alert(getAlertRequestChoiseMessage(language, min, max));
};

/**
 * 順番待ちの「希望条件の選択項目」の選択できない項目を押下した時にメッセージを取得
 * @param language
 * @param min
 * @param max
 */
export const getAlertRequestChoiseMessage = (language: string, min?: number, max?: number): string => {
  const minValue = !min || min === 0 ? 1 : min;

  if (max && max >= 0) {
    return (messageFunction.SELECT_REQUEST1_CHOICES_MIN_MAX(minValue, max)[language] as string) ?? '';
  } else {
    return (messageFunction.SELECT_REQUEST1_CHOICES_MIN(minValue)[language] as string) ?? '';
  }
};

/** 取引履歴をrTableに変換する */
export const getTableDataFromReceipt = (
  settings: Settings,
  account: AccountSetting,
  receipts: ReceiptJournalItem[]
): Table[] => {
  return receipts.map(
    (val) =>
      new Table({
        id: val.tableId,
        tableNo: val.tableNo,
        pickUpPlace: val.pickUpPlace,
        salesType: val.salesType,
        salesTypeValue: settings.getSalesTypeValue(val.salesType),
        availableCategoryTypes: [],
        numberOfGuests: val.guests,
        discount: -val.discount,
        usingPoint: val.usedPoint,
        fee: val.fee,
        taxSetting: account.taxSetting,
        note: '',
        order: [
          { id: '', orderData: val.orderData, orderType: '', orderedTime: Timestamp.fromDate(new Date(val.outtime)) },
        ],
        orderHistory: [],
        orderTickets: {},
        lineUsers: val.lineUsers.map(
          (v): LineUserLog => ({
            lineUserID: v.lineUserId,
            displayName: v.lineUserName,
            pictureUrl: v.pictureUrl,
            birthday: null,
            numberOfVisit: 1,
            loginTime: Timestamp.fromDate(new Date(val.entrytime)),
            lastTime: null,
          })
        ),
        lineUserIds: val.lineUsers.map((val) => val.lineUserId),
        paymentStatus: paymentStatus.paid,
        takeoutNote: val.takeoutNote,
        paymentMethod: val.paymentMethod,
        entrytime: Timestamp.fromDate(new Date(val.entrytime)),
        receivingAt: Timestamp.fromDate(new Date(val.outtime)),
      })
  );
};

/**
 * 消費税の丸め処理（消費税の計算以外に使用しないこと）
 *
 * @param tax
 * @param taxRounding
 * @returns
 */
export const taxRound = (tax: number, taxRounding: taxRoundingType): number => {
  switch (taxRounding) {
    case taxRoundingType.round:
      return Math.round(tax);
    case taxRoundingType.truncate:
      return Math.trunc(tax);
    case taxRoundingType.ceil:
      return Math.ceil(tax);
    default:
      return Math.trunc(tax);
  }
};

/**
 * 軽減税率を適用するかどうか（税率自動決定商品向け）
 *
 * @param p
 * @returns
 */
export const isReducedTaxRate = (p: { orderData: OrderData; salesType?: salesType }): boolean => {
  // 税率自動決定がtrue、それ以外は商品の設定値の税率を使用
  if (p.orderData.isAutoTaxRateApplied && p.salesType) {
    // salesTypeがtakeout, takeout2, delivert, curbsideの場合、税率を強制的に軽減税率に変更
    if ([salesType.takeout, salesType.takeout2, salesType.delivery, salesType.curbside].includes(p.salesType)) {
      return true;
    } else {
      // eatin, dineInの場合は標準税率
      return false;
    }
  } else {
    return p.orderData.reducedTaxRate;
  }
};

/**
 * 注文データから支払い情報を生成する
 *
 * @param p
 */
export const getPayment = (p: {
  orderedItems: OrderData[];
  discount: number;
  deliveryFee: number;
  deliveryFeeLimitToFree: number;
  point?: number;
  coupon?: UsableCoupon | null;
  giftFee?: number;
  paymentFee?: number;
  serviceChargeRate?: number;
  taxRounding: taxRoundingType;
  timeFrame: { checkIn: Date; checkOut: Date } | null;
}): Payment => {
  const taxRoundFunc = (tax: number) => taxRound(tax, p.taxRounding);

  const isNotReducedTaxMenuFilter = (o: OrderData) => !o.reducedTaxRate;
  const isReducedTaxMenuFilter = (o: OrderData) => o.reducedTaxRate;
  const isNotInternalTaxMenuFilter = (o: OrderData) => !o.taxIncluded;
  const isInternalTaxMenuFilter = (o: OrderData) => o.taxIncluded;

  const calcTotal = (sum: number, o: OrderData): number => sum + (o.price ?? 0);

  const calcTax = (t: number, r: number) => taxRoundFunc(t * r); // 税抜の計算->税丸め処理
  const calcIncludedTax = (t: number, taxRate: number) => taxRoundFunc((t / (taxRate + 1)) * taxRate); // 税抜の計算->税丸め処理

  const getTotal = (orderedItems: OrderData[], filteringFunction1, filteringFunction2) =>
    orderedItems.filter(filteringFunction1).filter(filteringFunction2).reduce(calcTotal, 0);

  const getMoreThanZero = (num: number) => (num < 0 ? 0 : num);

  const paymentData = (
    orderedItems: OrderData[],
    discount: number,
    fee = 0,
    feeLimitToFree = 0,
    giftFee = 0,
    paymentFee = 0,
    point = 0,
    coupon: UsableCoupon | null = null
  ): Payment => {
    // 値引き額を計算する関数
    const calculateDiscountValue = (total: number, discountAmount: number, totalSum: number): number => {
      const percentage = totalSum > 0 ? total / totalSum : 0;
      const discount = percentage * discountAmount;

      return discount;
    };
    // 値引き詳細の値引き額を計算する関数
    const calculateDiscountItemValue = (total: number, discountAmount: number, totalSum: number): number => {
      // 値引き額が小計合計を上回っている場合、値引き額を小計合計に合わせる
      const discountValue = adjustDiscountValue(discountAmount, totalSum);

      const discount = calculateDiscountValue(total, discountValue, totalSum);

      // 小数点切り捨て
      return Math.trunc(discount);
    };
    // 値引き額を調整する関数
    const adjustDiscountValue = (discountAmount: number, totalSum: number): number => {
      // 値引き額が小計合計を上回っている場合、値引き額を小計合計に合わせる
      return discountAmount > totalSum ? totalSum : discountAmount;
    };

    // 割引を計算する関数
    const calculateDiscountedTotal = (total: number, discountAmount: number, totalSum: number): number => {
      const discount = calculateDiscountValue(total, discountAmount, totalSum);

      // 割引が合計を上回らない場合のみ割引を適用
      const discountedTotal = total - discount;
      // 小数点切り捨て
      return Math.trunc(discountedTotal);
    };
    // 時間課金額の計算
    const calculateTimeBaseCharge = (minutes: number, baseMinutes: number, basePrice: number): number =>
      Math.floor(minutes / baseMinutes) * basePrice + basePrice;

    // orderedItemsに従量課金商品が含まれている場合、従量課金商品の金額を計算する
    const orderedFixedItems = orderedItems.map((item) =>
      item.timeBasedBilling?.enable && p.timeFrame
        ? {
            ...item,
            price: calculateTimeBaseCharge(
              getDiffMinutes(p.timeFrame.checkIn, p.timeFrame.checkOut),
              item.timeBasedBilling.basedMinutes,
              item.price
            ),
            unitPrice: calculateTimeBaseCharge(
              getDiffMinutes(p.timeFrame.checkIn, p.timeFrame.checkOut),
              item.timeBasedBilling.basedMinutes,
              item.unitPrice
            ),
          }
        : item
    );

    const total: number = getTotal(orderedFixedItems, isNotReducedTaxMenuFilter, isNotInternalTaxMenuFilter);
    const totalInternal: number = getTotal(orderedFixedItems, isNotReducedTaxMenuFilter, isInternalTaxMenuFilter);
    const totalReduced: number = getTotal(orderedFixedItems, isReducedTaxMenuFilter, isNotInternalTaxMenuFilter);
    const totalReducedInternal: number = getTotal(orderedFixedItems, isReducedTaxMenuFilter, isInternalTaxMenuFilter);

    const tax: number = calcTax(total, CommonSettings.TAX_RATE);
    const taxReduced: number = calcTax(totalReduced, CommonSettings.REDUCED_TAX_RATE);

    const totalSum = total + totalInternal + totalReduced + totalReducedInternal;

    const payFee = feeLimitToFree >= 1 && totalSum >= feeLimitToFree ? 0 : fee;

    const serviceCharge = p.serviceChargeRate
      ? Math.floor((totalSum + tax + taxReduced) * (p.serviceChargeRate * 0.01))
      : 0;

    const getCouponDiscount = (
      subTotal: number,
      coupon: { couponId: string; awardType: awardType; awardValue: number } | null
    ) => {
      if (!coupon) {
        return 0;
      }

      if (coupon.awardType === awardType.amountDiscount || coupon.awardType === awardType.C1) {
        return coupon.awardValue;
      } else if (coupon.awardType === awardType.percentDiscount || coupon.awardType === awardType.C2) {
        return Math.trunc(subTotal * (coupon.awardValue * 0.01));
      } else {
        return 0;
      }
    };

    // 小計
    const subTotal = totalSum + payFee + serviceCharge + giftFee + paymentFee;

    // クーポン値引き
    const couponDiscount = getCouponDiscount(subTotal, coupon);

    // 値引き合計
    const discountTotal = discount + point + couponDiscount;

    // 10%税込みの合計
    const totalInternalSum = totalInternal + payFee + serviceCharge + giftFee + paymentFee;

    // 小計値引き、金額で按分する
    const finalTotal = calculateDiscountedTotal(total, discountTotal, subTotal);
    const finalTotalInternal = calculateDiscountedTotal(totalInternalSum, discountTotal, subTotal);
    const finalTotalReduced = calculateDiscountedTotal(totalReduced, discountTotal, subTotal);
    const finalTotalReducedInternal = calculateDiscountedTotal(totalReducedInternal, discountTotal, subTotal);

    const finalTax: number = calcTax(finalTotal, CommonSettings.TAX_RATE);
    const finalTaxReduced: number = calcTax(finalTotalReduced, CommonSettings.REDUCED_TAX_RATE);

    const taxInternal: number = calcIncludedTax(finalTotalInternal, CommonSettings.TAX_RATE);
    const taxReducedInternal: number = calcIncludedTax(finalTotalReducedInternal, CommonSettings.REDUCED_TAX_RATE);

    const finalTotalSum = finalTotal + finalTotalInternal + finalTotalReduced + finalTotalReducedInternal;

    // 値引き後小計合計が値引き前小計合計-値引き額と誤差がある場合、調整額を計算
    const adjustmentAmount = subTotal - discountTotal - finalTotalSum;

    const pay =
      finalTotal +
      finalTotalInternal +
      finalTotalReduced +
      finalTotalReducedInternal +
      finalTax +
      finalTaxReduced +
      adjustmentAmount;

    const pointExcluded = orderedFixedItems.filter((v) => v.pointExcluded);

    const pointTotal: number = getTotal(pointExcluded, isNotReducedTaxMenuFilter, isNotInternalTaxMenuFilter);
    const pointTotalInternal: number = getTotal(pointExcluded, isNotReducedTaxMenuFilter, isInternalTaxMenuFilter);
    const pointTotalReduced: number = getTotal(pointExcluded, isReducedTaxMenuFilter, isNotInternalTaxMenuFilter);
    const pointTotalReducedInternal: number = getTotal(pointExcluded, isReducedTaxMenuFilter, isInternalTaxMenuFilter);

    const pointTax: number = calcTax(pointTotal, CommonSettings.TAX_RATE);
    const pointTaxReduced: number = calcTax(pointTotalReduced, CommonSettings.REDUCED_TAX_RATE);

    const pointTargetAmount =
      pay -
      (pointTotal + pointTotalInternal + pointTotalReduced + pointTotalReducedInternal + pointTax + pointTaxReduced);

    // 各種値引きの値を計算
    const discountDetail: DiscountDetail = {
      standardTax: {
        discount: calculateDiscountItemValue(total, discountTotal, subTotal),
        reducedTaxRate: false,
        taxIncluded: false,
      },
      standardTaxInternal: {
        discount: calculateDiscountItemValue(totalInternalSum, discountTotal, subTotal),
        reducedTaxRate: false,
        taxIncluded: true,
      },
      reducedTax: {
        discount: calculateDiscountItemValue(totalReduced, discountTotal, subTotal),
        reducedTaxRate: true,
        taxIncluded: false,
      },
      reducedTaxInternal: {
        discount: calculateDiscountItemValue(totalReducedInternal, discountTotal, subTotal),
        reducedTaxRate: true,
        taxIncluded: true,
      },
    };
    const discountDiff =
      adjustDiscountValue(discountTotal, subTotal) -
      (discountDetail.standardTax.discount +
        discountDetail.standardTaxInternal.discount +
        discountDetail.reducedTax.discount +
        discountDetail.reducedTaxInternal.discount);

    if (discountDiff > 0 && discountDetail.reducedTaxInternal.discount > 0) {
      discountDetail.reducedTaxInternal.discount += discountDiff;
    } else if (discountDiff > 0 && discountDetail.reducedTax.discount > 0) {
      discountDetail.reducedTax.discount += discountDiff;
    } else if (discountDiff > 0 && discountDetail.standardTaxInternal.discount > 0) {
      discountDetail.standardTaxInternal.discount += discountDiff;
    } else if (discountDiff > 0 && discountDetail.standardTax.discount > 0) {
      discountDetail.standardTax.discount += discountDiff;
    }

    const discountRes = discount <= subTotal ? discount : subTotal;
    const couponDiscountRes = couponDiscount <= subTotal - discountRes ? couponDiscount : subTotal - discountRes;

    const res = {
      subTotal: getMoreThanZero(subTotal), // ポイント対象,
      total: getMoreThanZero(finalTotal + finalTotalInternal + finalTax),
      totalReduced: getMoreThanZero(finalTotalReduced + finalTotalReducedInternal + finalTaxReduced),
      tax: getMoreThanZero(finalTax),
      taxInternal: getMoreThanZero(taxInternal + finalTax), // 消費税（税抜、税込）の合計 TODO:名前をtaxTotalに変更
      includedTax: getMoreThanZero(taxInternal), // 税込商品の税額合計 TODO:名前をtaxInternalに変更
      taxReduced: getMoreThanZero(finalTaxReduced),
      taxReducedInternal: getMoreThanZero(taxReducedInternal + finalTaxReduced), // 消費税（税抜、税込）の合計 TODO:名前をtaxReducedTotalに変更
      includedReducedTax: getMoreThanZero(taxReducedInternal), // 税込商品の税額合計 TODO:名前をtaxReducedInternalに変更
      serviceCharge: serviceCharge > 0 ? serviceCharge : 0,
      fee: payFee,
      giftFee: giftFee,
      paymentFee: paymentFee,
      discount: discountRes,
      couponDiscount: couponDiscountRes,
      coupon: p.coupon ?? null,
      point: point <= subTotal ? point : subTotal,
      pointTargetAmount: pointTargetAmount > 0 ? getMoreThanZero(pointTargetAmount) : 0, // ポイント対象,
      discountDetail: discountDetail,
      pay: getMoreThanZero(pay), // 税込 or 税抜,
      orderedItems: orderedFixedItems,
    };

    // 調整額を算入 totalが0円より大きい場合はtotalに加算、小さい場合はtotalReducedに加算
    if (res.total > 0) {
      res.total += adjustmentAmount;
    } else {
      res.totalReduced += adjustmentAmount;
    }

    return res;
  };
  const result = paymentData(
    p.orderedItems,
    p.discount,
    p.deliveryFee,
    p.deliveryFeeLimitToFree,
    p.giftFee,
    p.paymentFee,
    p.point,
    p.coupon
  );

  return result;
};

/** ハンディ、セルフオーダーで使用 */
export const makeOrderObject = (orderItem: OrderItem, menu: Menu, isMember: boolean): OrderModel => {
  const id = orderItem.id ? orderItem.id : makeNewUuid();

  const order: OrderModel = {
    key: id,
    id: id,
    menuId: orderItem.menuId,
    originalMenuId: orderItem.originalMenuId ?? orderItem.menuId,
    count: orderItem.count,
    name: orderItem.name,
    isOpenPrice: orderItem.isOpenPrice,
    price: 0,
    // オープン価格商品は設定された単価を使用、それ以外は商品マスタの現在価格を使用
    unitPrice: orderItem.isOpenPrice ? orderItem.unitPrice : menu.getPrice(isMember),
    timeBasedBilling: menu.params.timeBasedBilling ?? null,
    reducedTaxRate: orderItem.reducedTaxRate ? true : false,
    taxType: orderItem.taxType,
    taxIncluded: orderItem.taxIncluded ? true : false,
    pointExcluded: orderItem.pointExcluded ? true : false,
    isAutoTaxRateApplied: orderItem.isAutoTaxRateApplied ? true : false,
    subMenu: orderItem.subMenus
      .map(
        (subMenu): OrderSubMenu => ({
          title: subMenu.title,
          items: subMenu.items
            .filter((val) => {
              const selectedItemKeys = Array.isArray(subMenu.selectedItems)
                ? subMenu.selectedItems
                : [subMenu.selectedItems];
              return selectedItemKeys.includes(val.name);
            })
            .reduce(
              (res: { [key: string]: OrderSubMenuItem }, val): { [key: string]: OrderSubMenuItem } => ({
                ...res,
                [val.name]: {
                  itemName: val.name,
                  count: orderItem.count,
                  unitPrice: val.addition,
                  itemCode: val.itemCode ?? null,
                },
              }),
              {}
            ),
        })
      )
      .filter((val) => Object.keys(val.items).length > 0), // アイテム未選択のサブメニューは除外

    ...(orderItem.otherRequests ? { otherRequests: orderItem.otherRequests } : {}),
    ...(orderItem.preOrderDeadline ? { preOrderDeadline: orderItem.preOrderDeadline } : {}),
    ...(orderItem.specifyDate ? { specifyDate: orderItem.specifyDate } : {}),
    ...(menu.params.waiterMenuType ? { waiterMenuType: menu.params.waiterMenuType } : {}),
  };

  // サブメニューあり、かつオープン価格商品でない場合
  if (order.subMenu.length > 0 && !orderItem.isOpenPrice) {
    const getAddition = (subMenuItems: { [key: string]: OrderSubMenuItem }): number =>
      Object.keys(subMenuItems)
        .filter((item) => subMenuItems[item].count !== 0)
        .reduce((res: number, key) => res + subMenuItems[key].unitPrice * subMenuItems[key].count, 0);

    order.price =
      menu.getPrice(isMember) * order.count + order.subMenu.reduce((res, val) => res + getAddition(val.items), 0);
  } else {
    // 全体の個数を直接設定する場合
    order.price = order.unitPrice * orderItem.count;
  }

  return order;
};

export const hasSameTableNo = (tables: { [key: string]: Table }, value: string): boolean =>
  Object.keys(tables).some((key) => tables[key].isEatin && tables[key].params.tableNo === value);

export const isMatchedTransaction = (sales: salesType, transaction: transactionType): boolean => {
  if (transaction === transactionType.eatin) {
    return sales === salesType.eatin;
  } else if (transaction === transactionType.picking) {
    return [salesType.takeout, salesType.takeout2, salesType.curbside, salesType.delivery, salesType.dineIn].includes(
      sales
    );
  } else if (transaction === transactionType.ec) {
    return false;
  }
  return false;
};

export const getPriceIncludingTax = (price: number, reducedTaxRate: boolean, taxRounding: taxRoundingType): number =>
  taxRound(price + price * (reducedTaxRate ? CommonSettings.REDUCED_TAX_RATE : CommonSettings.TAX_RATE), taxRounding);

export const getAddress = (value: CustomerInfo): string =>
  value ? `〒${value.postcode} ${value.prefecture}${value.address} ${value.building}` : '';

/** 郵便番号で住所検索 */
export const searchAddress = async (
  zipCode: string
): Promise<{
  pref: string; // 福岡県
  city: string; // 福岡市中央区
  town: string; // 大名
  address: string; // 福岡市中央区大名
  fullAddress: string; // 福岡県福岡市中央区大名
} | null> => {
  if (zipCode.length < 7) {
    return null;
  }

  const response = await axios.get(`https://api.zipaddress.net/?zipcode=${zipCode}`, { adapter: jsonpAdapter });

  const data = response.data;

  // codeがある = エラー
  if (data.code) {
    return null;
  }

  return data;
};

/** スペースを改行へ変換 **/
export const getExchangeLine = (str: string): string => str.replace(/\s+/g, '\r\n');

/** SMS認証を行う */
export const authSMS = async (obj: {
  contractId: string;
  phoneNumber: string;
  phoneName: string;
  expireName: string;
}): Promise<boolean> => {
  const storagePhone = localStorage.getItem(obj.phoneName);
  const storageTime = localStorage.getItem(obj.expireName);

  if (storagePhone && storageTime && parseInt(storageTime) >= new Date().getTime()) {
    return true;
  }

  // SMS認証を送信
  const authCode = (Math.floor(Math.random() * (999999 + 1 - 100000)) + 100000).toString();
  const smsAuthStore = useSmsAuthStore();
  await smsAuthStore.send({ contractId: obj.contractId, to: obj.phoneNumber, authCode: authCode });

  const code = window.prompt('認証コードを入力してください Please enter the authentication code.', '');
  if (!code || code !== authCode) return false;

  localStorage.setItem(obj.phoneName, obj.phoneNumber);
  localStorage.setItem(obj.expireName, (new Date().getTime() + 60 * 60 * 24 * 1000).toString());

  return true;
};

/** 会員ランクを取得 */
export const getMembersRank = (membersRankList: MembersRank[], rankId: string): MembersRank | undefined => {
  return membersRankList.find((val) => val.id === rankId);
};

/** 端末利用権限を取得 */
export const getPermission = (): Permission => {
  return (localStorage.getItem(CommonSettings.WEB_STORAGE_KEY.PERMISSION) as Permission) ?? Permission.admin;
};

/** 10進数表記にエスケープ */
export const escape = (val: string): string => {
  return val.replace('&', '&#38;');
};

/** groupBy **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T extends { [key: string]: any }>(objects: T[], key: keyof T): { [key: string]: T[] } =>
  objects.reduce(
    (map, obj) => {
      map[obj[key]] = map[obj[key]] || [];
      map[obj[key]].push(obj);
      return map;
    },
    {} as { [key: string]: T[] }
  );

export const makeNewCategoryId = (): string => nanoid();
export const makeNewSubCategoryId = (): string => nanoid();
export const makeNewMenuId = (): string => nanoid();
export const makeNewUuid = (): string => nanoid();

export const copyText = (text: string): void => {
  window.focus(); // フォーカスを当てないとコピーできない（chrome対策）
  navigator.clipboard.writeText(text).then(
    () => alert('コピーしました'),
    (error) => alert(error.message)
  );
};

/**
 * 位置情報から距離を計測する
 *
 * @param position1
 * @param position2
 */
export const getDistance = (
  position1: { lat: number; lng: number },
  position2: { lat: number; lng: number }
): number => {
  const lat1 = (position1.lat * Math.PI) / 180;
  const lng1 = (position1.lng * Math.PI) / 180;
  const lat2 = (position2.lat * Math.PI) / 180;
  const lng2 = (position2.lng * Math.PI) / 180;

  const distance: number =
    Math.floor(
      6371 * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2)) * 100
    ) / 100;

  return distance;
};

/**
 * 注文開始QRコードの印刷
 *
 * @param p
 */
export const WaitingTicketPrint = async (p: {
  serialNumber: string;
  storeName: string;
  tel: string;
  referenceNumber: string;
  language: languageType;
  url: string;
  people: number | null;
  kids: number | null;
  request1: string;
}): Promise<void> => {
  const executeAddedCode = (printer) => {
    if (!printer) {
      return;
    }
    printer.addSound(printer.PATTERN_A, 1);
    printer.addCut(printer.CUT_RESERVE);
    printer.addTextLang('ja');
    printer.addTextSmooth(true);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addText('\n');
    printer.addTextSize(1, 1);
    printer.addTextStyle(false, false, false, printer.COLOR_1);
    printer.addText(dateTimeFormatJapaneseWithWeekDay(new Date()));
    printer.addText('\n');
    printer.addText('\n');
    printer.addTextSize(1, 1);
    printer.addText('受付番号');
    printer.addText('\n');
    printer.addText('\n');
    printer.addTextSize(6, 6);
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    printer.addText(p.referenceNumber);
    printer.addTextSize(1, 1);
    printer.addText('\n');
    printer.addText('\n');

    // 人数がある場合は表示
    if (p.people) {
      const hasKids = p.kids && p.kids > 0;

      printer.addText(`${hasKids ? '大人' : '人数'}:${p.people}名`);
      if (hasKids) {
        printer.addText(` 子ども:${p.kids}名`);
      }
      printer.addText('\n');
    }
    // 希望条件1がある場合は表示
    if (p.request1) {
      printer.addText(`${p.request1}`);
      printer.addText('\n');
    }
    printer.addText('\n');

    // URLがある場合はQRコードを表示
    if (p.url) {
      printer.addText(
        p.language === 'ja'
          ? 'QRコードを読み込むとLINEで通知を受け取れます'
          : 'You can check where you are in the waiting line. Scan QR Code.'
      );
      printer.addText('\n');
      printer.addText('\n');
      printer.addSymbol(p.url, printer.SYMBOL_QRCODE_MODEL_2, printer.LEVEL_Q, 6, 0, 0);
    } else {
      printer.addText(
        p.language === 'ja'
          ? '順番になりましたらお呼びします。'
          : 'We will call you when it is your turn. Please wait for a while.'
      );
      printer.addText('\n');
    }

    printer.addText('\n');
    printer.addText(p.storeName);
    printer.addText('\n');
    printer.addText(`TEL: ${p.tel}`);
    printer.addText('\n');
    printer.addText('\n');
    printer.addCut(printer.CUT_RESERVE);
    printer.send();
  };

  await execPrint(p.serialNumber, executeAddedCode);
};

/**
 * 注文開始QRコードの印刷
 *
 * @param p
 */
export const orderQRCodePrint = async (p: {
  serialNumber: string;
  storeName: string;
  tableNo: string;
  tableId: string;
  url: string;
}): Promise<void> => {
  const executeAddedCode = (printer) => {
    if (!printer) {
      return;
    }
    printer.addCut(printer.CUT_RESERVE);
    printer.addTextLang('ja');
    printer.addTextSmooth(true);
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addTextSize(1, 1);
    printer.addText(p.storeName);
    printer.addText('\n');
    printer.addText('\n');
    printer.addText('来店日時');
    printer.addText('\n');
    printer.addTextSize(1, 1);
    printer.addTextStyle(false, false, false, printer.COLOR_1);
    printer.addText(dateTimeFormatJapaneseWithWeekDay(new Date()));
    printer.addText('\n');
    printer.addText('\n');
    printer.addTextSize(4, 4);
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    printer.addText(p.tableNo);
    printer.addTextSize(1, 1);
    printer.addText('\n');
    printer.addText('\n');
    printer.addText('QRコードを読み込むとスマートフォンで注文ができます');
    printer.addText('\n');
    printer.addText('\n');
    printer.addSymbol(p.url, printer.SYMBOL_QRCODE_MODEL_2, printer.LEVEL_Q, 6, 0, 0);
    printer.addText('\n');
    printer.addText(`No: ${p.tableId}`);
    printer.addText('\n');
    printer.addText('\n');
    printer.addCut(printer.CUT_RESERVE);
    printer.send();
  };

  await execPrint(p.serialNumber, executeAddedCode);
};

/**
 * 印刷実行
 *
 * @param serialNumber 端末のシリアル番号
 * @param code 印刷コード
 */
export const execPrint = async (serialNumber: string, code: (printer) => void): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ePosDev = new (window as any).epson.ePOSDevice();

  const cbCreateDevice_printer = (devobj, retcode) => {
    if (retcode == 'OK') {
      code(devobj);
    } else {
      alert(`印刷できませんでした。プリンターの状態、または設定を確認してください。\n\nERROR CODE: ${retcode}`);
    }
  };

  const cbConnect = (data) => {
    if (data == 'OK') {
      ePosDev.createDevice(
        'local_printer',
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        cbCreateDevice_printer
      );
    } else {
      alert(
        `プリンターに接続できません。プリンターの電源、またはネットワーク接続を確認してください\n\nERROR CODE: ${data}`
      );
    }
  };

  const encodeBase32 = (byteArray) => {
    const bit5toBase32Dic = {
      '00000': 'A',
      '00001': 'B',
      '00010': 'C',
      '00011': 'D',
      '00100': 'E',
      '00101': 'F',
      '00110': 'G',
      '00111': 'H',
      '01000': 'I',
      '01001': 'J',
      '01010': 'K',
      '01011': 'L',
      '01100': 'M',
      '01101': 'N',
      '01110': 'O',
      '01111': 'P',
      '10000': 'Q',
      '10001': 'R',
      '10010': 'S',
      '10011': 'T',
      '10100': 'U',
      '10101': 'V',
      '10110': 'W',
      '10111': 'X',
      '11000': 'Y',
      '11001': 'Z',
      '11010': '2',
      '11011': '3',
      '11100': '4',
      '11101': '5',
      '11110': '6',
      '11111': '7',
    };

    let byteText = '';
    for (let i = 0; i < byteArray.length; i++) {
      byteText += String(Number(byteArray[i]).toString(2)).padStart(8, '0');
    }

    const bit5Array = byteText.match(/.{1,5}/g);
    let base32Text = '';
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (let i = 0; i < bit5Array!.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      let bit5Text = bit5Array![i];
      if (bit5Text.length < 5) {
        bit5Text = bit5Text + '0'.repeat(5 - bit5Text.length);
      }
      if (bit5Text in bit5toBase32Dic) {
        base32Text += bit5toBase32Dic[bit5Text];
      }
    }

    return base32Text;
  };

  const connectHostName = async (serial: string) => {
    const epsonDomain = 'omnilinkcert.epson.biz';

    // Computes the SHA-256
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const buff = new Uint8Array(([] as any).map.call(serial, (c) => c.charCodeAt(0))).buffer;
    const hashHex = await crypto.subtle.digest('SHA-256', buff);

    // Encode the Base32
    const base32Text = encodeBase32(new Uint8Array(hashHex));

    return base32Text + '.' + epsonDomain;
  };

  const target = await connectHostName(serialNumber);

  await ePosDev.connect(target, ePosDev.IFPORT_EPOSDEVICE_S, cbConnect, { eposprint: true });
};

/**
 * クーポンが有効かどうか
 *
 * @param p
 */
export const isValidCoupon = (p: { used: boolean; startDate?: Date; expireDate?: Date }): boolean => {
  // 使用済み = 無効
  if (p.used) {
    return false;
  }

  const now = new Date();

  // 開始日と終了日が設定されている場合
  if (p.startDate && p.expireDate) {
    return isDateSameOrAfter(now, new Date(p.startDate)) && isDateSameOrBefore(now, new Date(p.expireDate));
  }
  // 開始日のみ設定されている場合
  if (p.startDate && !p.expireDate) {
    return isDateSameOrAfter(now, new Date(p.startDate));
  }
  // 終了日のみ設定されている場合
  if (!p.startDate && p.expireDate) {
    return isDateSameOrBefore(now, new Date(p.expireDate));
  }

  // 開始日と終了日が設定されていない場合は有効
  return true;
};

/**
 * ストレージに保存されている画像URLを取得
 * @param image ストレージに保存する画像の情報
 * @param defaultValue 画像が存在しない場合の初期値
 */
export const getStrageImageUrl = (image: ImageObject | null, defaultValue?: string): string => {
  return image?.url && image?.file ? image?.url : !image?.url && !image?.file ? '' : defaultValue ?? '';
};

/**
 * 配送料金を返す
 */
export const getDeliveryFee = (deliveryFee: DeliveryFeesByPostalCode | null, defaultValue: number): number => {
  return deliveryFee?.deliveryFee ?? defaultValue;
};

/**
 * 配達料金無料境界値を返す
 */
export const getDeliveryFeeLimitToFree = (
  deliveryFee: DeliveryFeesByPostalCode | null,
  defaultValue: number
): number => {
  return deliveryFee?.freeDeliveryThreshold ?? defaultValue;
};

/**
 * オブジェクト内のDate型をISO8601形式の文字列に変換
 *
 * @param obj
 * @returns
 */
export const serializeDates = (obj) => {
  if (!obj) {
    return obj;
  }

  if (obj instanceof Date) {
    return obj.toISOString();
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => serializeDates(item));
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = serializeDates(obj[key]);
      return acc;
    }, {});
  }

  return obj;
};

/**
 * オブジェクト内のISO8601形式の日付文字列をDate型に変換
 *
 * @param obj
 * @returns
 */
export const deserializeDates = (obj) => {
  if (!obj) {
    return obj;
  }

  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

  if (typeof obj === 'string' && iso8601Regex.test(obj)) {
    return new Date(obj);
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deserializeDates(item));
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = deserializeDates(obj[key]);
      return acc;
    }, {});
  }

  return obj;
};

/**
 * デモアプリかどうか
 */
export const isDemoApp = (contractId: string): boolean => {
  return CommonSettings.DEMO_ACCOUNT.includes(contractId);
};

/**
 * ローカルかどうか
 */
export const isLocal = (): boolean => {
  return process.env.VUE_APP_MODE === 'local';
};

/**
 * 画面端のタッチイベントを無効にする
 * iOSのエッジスワイプを無効化する用途
 * この関数は直接呼び出さず、addEdgeTouchDisableを使用すること
 *
 * @param e
 */
const edgeTouchDisable = (e: TouchEvent) => {
  // タッチが画面端 (16ピクセル以内) で始まったかを確認
  const touch = e.touches[0];
  const isEdgeSwipe = touch.pageX <= 16 || touch.pageX >= window.innerWidth - 16;

  if (isEdgeSwipe) {
    e.preventDefault(); // デフォルトのスワイプバック動作を無効化
  }
};

/**
 * iPad、iPhoneのエッジスワイプを無効化
 */
export const addEdgeTouchDisable = (): void => {
  document.body.addEventListener('touchstart', edgeTouchDisable, { passive: false });
};

/**
 * iPad、iPhoneのエッジスワイプを有効化
 */
export const removeEdgeTouchDisable = (): void => {
  document.body.removeEventListener('touchstart', edgeTouchDisable);
};
