import {
  DailyStock,
  DailyStockParam,
  MultipleSubMenuLimit,
  RMenu,
  RecommendColor,
  SubMenu,
  SubMenuItem,
} from '@/store/models/db/r-menu';
import { OrderingSubMenu } from './db/common-models';
import { OrderItem } from '@/store/models/db/common-models';
import { MenuCategory } from './menu-category';
import * as Utils from '@/common/utils';
import { salesType, stockCondition, stockType, taxRoundingType, taxType } from '@/common/appCode';
import { OrderData } from './db/r-tables';
import { OrderModel } from './order';

export interface MenuModel extends RMenu {}

export class Menu {
  public params: MenuModel;

  constructor(params: RMenu) {
    this.params = { ...params };
  }

  /** 1つのサブカテゴリに登録できるメニューの最大数の取得 */
  public static MAX_NUMBER_OF_MENU_REGISTERED = 6;

  /** メニュー名の登録可能最大文字数 */
  public static MAX_MENU_NAME_LENGTH = 60;
  /** メニュー名の略称最大文字数 */
  public static MAX_MENU_SHORT_NAME_LENGTH = 15;
  /** おすすめの登録可能最大文字数 */
  public static MAX_RECOMMEND_TEXT_LENGTH = 10;
  /** オプション商品の最大数 */
  public static MAX_OPTION_PRODUCT_NUMBER = 8;
  /** 有料レジ袋のメニュー名の登録可能最大文字数 */
  public static MAX_MENU_NAME_PLASTIC_BAG_LENGTH = 10;
  /** 説明の登録可能最大文字数 */
  public static MAX_DESCRIPTION_LENGTH = 1000;
  /** サブメニュータイトルの登録可能最大文字数 */
  public static MAX_SUB_MENU_TITLE_LENGTH = 20;
  /** サブメニュータイトル(多言語)の登録可能最大文字数 */
  public static MAX_MULTILINGUAL_SUB_MENU_TITLE_LENGTH = 25;
  /** サブメニュー名の登録可能最大文字数 */
  public static MAX_SUB_MENU_NAME_LENGTH = 60;
  /** メニュー金額の登録可能最大文字数 */
  public static MAX_PRICE_LENGTH = 6;
  /** サブメニューの最大登録可能数 */
  public static MAX_SUB_MENU = 10;
  /** サブメニュー項目の最大登録可能数 */
  public static MAX_SUB_MENU_ITEMS = 20;

  /** おすすめ背景色の初期値 */
  static get DEFAULT_RECOMMEND_COLOR(): RecommendColor {
    return { color: '', dark: true };
  }
  /** 多言語対応項目の初期値 */
  static get DEFAULT_MULTILINGUAL(): { ja: string; en: string; zh: string; ko: string } {
    return { ja: '', en: '', zh: '', ko: '' };
  }
  /** 日別在庫設定の初期値 */
  static get DEFAULT_DAILY_STOCK(): DailyStock {
    return {
      type: stockType.all,
      enable: false,
      stockBasicNumber: 0,
      stock: {},
      stockBasicNumberHourly: [],
      stockHourly: {},
    };
  }

  get menuObject(): RMenu {
    const res = { ...this.params };
    const aa = 'key';
    delete res[aa];
    return this.params;
  }

  /**
   * サブメニューかオープン価格メニューがあるメニューか
   */
  get hasDetail(): boolean {
    return this.subMenu.length > 0 || this.params.optional_price;
  }

  get getEntity(): RMenu {
    return {
      menuId: this.params.menuId,
      storeId: this.params.storeId,
      name: this.params.name,
      shortName: this.params.shortName,
      division: this.params.division,
      optionProducts: this.params.optionProducts,
      timeBasedBilling: this.params.timeBasedBilling,
      price: this.params.price,
      memberPrice: this.params.memberPrice,
      subMenu: this.subMenu,
      category_no: this.params.category_no,
      sub_category_no: this.params.sub_category_no,
      available: this.params.available,
      orderLimit: this.params.orderLimit,
      orderMinimum: this.params.orderMinimum,
      stock: this.stock,
      stockAutoReset: this.params.stockAutoReset,
      defaultStockNum: this.params.defaultStockNum,
      transferKitchen1: this.params.transferKitchen1,
      transferKitchen2: this.params.transferKitchen2,
      positions: this.positionIds,
      categoryType: this.params.categoryType,
      image_url: this.params.image_url,
      youtubeId: this.params.youtubeId,
      description: this.params.description,
      recipe: this.params.recipe,
      helpText: this.params.helpText,
      optional_price: this.params.optional_price,
      storeOnly: this.params.storeOnly,
      reducedTaxRate: this.params.reducedTaxRate,
      taxType: this.taxType,
      taxIncluded: this.params.taxIncluded,
      isAutoTaxRateApplied: this.params.isAutoTaxRateApplied,
      pointExcluded: this.params.pointExcluded,
      recommended: this.params.recommended,
      recommendText: this.params.recommendText,
      recommendColor: this.params.recommendColor,
      multipleSubMenu: this.params.multipleSubMenu,
      multipleSubMenuLimit: this.params.multipleSubMenuLimit,
      sort_key: this.params.sort_key,
    };
  }

  get stock(): number {
    return this.params.stock ?? 0;
  }

  get dailyStock(): { [key: string]: DailyStockParam } {
    return this.params.dailyStock?.stock ?? ({} as { [key: string]: DailyStockParam });
  }

  get orderLimit(): number {
    return this.params.orderLimit ?? 0;
  }

  get multipleSubMenuLimit(): MultipleSubMenuLimit {
    return this.params.multipleSubMenuLimit ?? { lower: 0, upper: 0 };
  }

  get isDailyStockEnabled(): boolean {
    return this.params.dailyStock?.enable ?? false;
  }

  get subMenu(): SubMenu[] {
    const subMenu = this.params.subMenu ?? [
      ...(this.params.subMenu1 ? [this.params.subMenu1] : []),
      ...(this.params.subMenu2 ? [this.params.subMenu2] : []),
    ];

    return subMenu.map(
      (val, i): SubMenu => ({
        ...val,
        multilingualTitles: val.multilingualTitles ?? Menu.DEFAULT_MULTILINGUAL,
        items: val.items.map(
          (val2): SubMenuItem => ({ ...val2, multilingualNames: val2.multilingualNames ?? Menu.DEFAULT_MULTILINGUAL })
        ),
        multiple:
          val.multiple ??
          (this.params.multipleSubMenu && i === 0
            ? { enable: true, lower: this.multipleSubMenuLimit.lower, upper: this.multipleSubMenuLimit.upper }
            : { enable: false, lower: 0, upper: 0 }),
      })
    );
  }

  get subMenuIds(): string[] {
    return this.params.subMenuIds ?? [];
  }

  get isAutoTaxRateApplied(): boolean {
    return this.params.isAutoTaxRateApplied ?? false;
  }

  get positionIds(): string[] {
    return this.params.positions ?? [this.params.kitchen_no];
  }

  get taxType(): taxType {
    // TODO:暫定対応 reducedTaxRateはレガシー
    return this.params.taxType ?? (this.params.reducedTaxRate ? taxType.reduced : taxType.normal);
  }

  get recommendColor(): string {
    return this.params.recommendColor?.color ?? 'red';
  }

  get recommendDark(): string {
    return !this.params.recommendColor ? 'white' : this.params.recommendColor.dark ? 'white' : 'black';
  }

  /**
   * 軽減税率を適用するかどうか（税率自動決定商品向け）
   *
   * @param p
   * @returns
   */
  decidesReducedTaxRate(type: salesType): boolean {
    // 税率自動決定がtrue、それ以外は商品の設定値の税率を使用
    if (this.params.isAutoTaxRateApplied) {
      // salesTypeがtakeout, takeout2, delivert, curbsideの場合、税率を強制的に軽減税率に変更
      if ([salesType.takeout, salesType.takeout2, salesType.delivery, salesType.curbside].includes(type)) {
        return true;
      } else {
        // eatin, dineInの場合は標準税率
        return false;
      }
    } else {
      return this.params.reducedTaxRate;
    }
  }

  priceTaxIn(taxRounding: taxRoundingType, isMember: boolean): number {
    return this.params.taxIncluded
      ? this.getPrice(isMember)
      : Utils.getPriceIncludingTax(this.getPrice(isMember), this.params.reducedTaxRate, taxRounding);
  }

  getPrice(isMember: boolean): number {
    return isMember && this.params.memberPrice > 0 ? this.params.memberPrice : this.params.price;
  }

  /**
   * 在庫情報の変更
   */
  changeStockStatus(): void {
    this.params.available = !this.params.available;
  }

  getStock(targetDate: string | null, targetTime?: string | null): number {
    // TODO:typeがundefined又はnullの処理は過渡期対策
    return this.params.dailyStock?.enable &&
      (!this.params.dailyStock.type || this.params.dailyStock.type === stockType.all) &&
      targetDate
      ? this.getDailyStock(targetDate)
      : this.params.dailyStock?.enable && this.params.dailyStock.type === stockType.hourly && targetDate && targetTime
        ? this.getHourlyStock(targetDate, targetTime)
        : this.stock;
  }

  isSoldOut(targetDate: string | null, targetTime?: string | null): boolean {
    // TODO:typeがundefined又はnullの処理は過渡期対策
    return this.params.dailyStock?.enable &&
      (!this.params.dailyStock.type || this.params.dailyStock.type === stockType.all) &&
      targetDate
      ? this.getDailyStockCondition(targetDate) === stockCondition.soldout
      : this.params.dailyStock?.enable && this.params.dailyStock.type === stockType.hourly && targetDate && targetTime
        ? this.getHourlyStockCondition(targetDate, targetTime) === stockCondition.soldout
        : !this.params.available;
  }

  /**
   * 日別在庫数取得
   * 日別在庫数がない場合は基準在庫数を返す
   *
   * @param targetDate
   * @returns
   */
  getDailyStock(targetDate: string): number {
    if (!this.params.dailyStock) {
      return 0;
    }
    if (!targetDate) {
      return this.params.dailyStock?.stockBasicNumber ?? 0;
    }

    return this.params.dailyStock.stock?.[targetDate]?.stock ?? this.params.dailyStock.stockBasicNumber ?? 0;
  }

  /**
   * 時間帯別在庫数取得
   * 時間帯別在庫数がない場合は基準在庫数を返す
   *
   * @param targetDate
   * @param targetTime
   * @returns
   */
  getHourlyStock(targetDate: string, targetTime: string): number {
    if (!this.params.dailyStock) {
      return 0;
    }
    const stock =
      this.params.dailyStock.stockHourly[targetDate] ??
      this.params.dailyStock.stockBasicNumberHourly.map((item) => ({ ...item, condition: stockCondition.orderable }));
    return (
      stock.find((v) =>
        Utils.isBetween(
          Utils.getDateFromString(targetDate, targetTime),
          Utils.getDateFromString(targetDate, v.from),
          Utils.getDateFromString(targetDate, v.to),
          '[)'
        )
      )?.stock ?? 0
    );
  }

  /**
   * 日別在庫の販売可否を取得
   *
   * @param targetDate
   * @returns
   */
  getDailyStockCondition(targetDate: string): stockCondition {
    if (!this.params.dailyStock || !targetDate) {
      return stockCondition.orderable;
    }

    return this.params.dailyStock.stock?.[targetDate]?.condition ?? stockCondition.orderable;
  }

  /**
   * 時間帯別在庫の販売可否を取得
   *
   * @param targetDate
   * @param targetTime
   * @returns
   */
  getHourlyStockCondition(targetDate: string, targetTime: string): stockCondition {
    if (!this.params.dailyStock || !targetDate || !targetTime) {
      return stockCondition.orderable;
    }

    if (this.params.dailyStock.stockHourly[targetDate]) {
      const stock = this.params.dailyStock.stockHourly[targetDate].find((v) =>
        Utils.isBetween(
          Utils.getDateFromString(targetDate, targetTime),
          Utils.getDateFromString(targetDate, v.from),
          Utils.getDateFromString(targetDate, v.to),
          '[)'
        )
      );
      return stock?.condition ?? stockCondition.orderable;
    } else if (this.params.dailyStock.stockBasicNumberHourly.length) {
      const stock = this.params.dailyStock.stockBasicNumberHourly.find((v) =>
        Utils.isBetween(
          Utils.getDateFromString(targetDate, targetTime),
          Utils.getDateFromString(targetDate, v.from),
          Utils.getDateFromString(targetDate, v.to),
          '[)'
        )
      );
      return !stock || stock.stock > 0 ? stockCondition.orderable : stockCondition.soldout;
    }
    return stockCondition.orderable;
  }

  convertOrderSubMenus(orderedItem?: OrderData | OrderModel): OrderingSubMenu[] {
    return this.subMenu.map(
      (val, i): OrderingSubMenu => ({
        title: val.title,
        multilingualTitles: val.multilingualTitles,
        items: val.items,
        selectedItems: orderedItem
          ? (this.params.multipleSubMenu && i === 0) || val.multiple?.enable
            ? Object.keys(orderedItem.subMenu?.find((v) => v.title === val.title)?.items ?? [])
            : Object.keys(orderedItem.subMenu?.find((v) => v.title === val.title)?.items ?? [])[0] ?? ''
          : [],
        multiple: val.multiple,
      })
    );
  }

  convertOrderItem(isMember: boolean, category?: MenuCategory): OrderItem {
    return {
      id: '',
      orderId: '',
      menuId: this.params.menuId,
      originalMenuId: this.params.menuId,
      name: this.params.name,
      subMenus: this.convertOrderSubMenus(),
      otherRequests: '',
      count: 1,
      isOpenPrice: this.params.optional_price,
      price: 0,
      unitPrice: this.getPrice(isMember),
      reducedTaxRate: this.params.reducedTaxRate,
      taxType: this.taxType,
      taxIncluded: this.params.taxIncluded,
      isAutoTaxRateApplied: this.params.isAutoTaxRateApplied,
      pointExcluded: this.params.pointExcluded,
      ...(category?.params.preOrderDeadline ? { preOrderDeadline: category.params.preOrderDeadline } : {}),
      ...(category?.params.specifyDate ? { specifyDate: category.params.specifyDate } : {}),
    };
  }
}
