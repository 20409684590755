import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';
import * as Utils from '@/common/utils';
import { Menu } from '@/store/models/menu';
import { RMenu } from '@/store/models/db/r-menu';
import { StoreError } from '@/common/error';
import { CommonSettings } from '@/common/common-settings';
import { api } from '@/firebase/api';
import { ImportMenuFromPosRes } from '@/firebase/dto';
import { MenuImages } from '@/store/models/common-sys-models';
import { docs, storageRef } from '@/firebase/dao';
import { Unsubscribe, onSnapshot } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import { division } from '@/common/appCode';
import { UpdateSmaregiProductReq, UpdateSmaregiProductRes } from '@/firebase/dto/smaregi';
import { UpsertMenuReq, UpsertMenuRes } from '@/firebase/dto/update-menu';
import { useUsersStore } from '@/stores/users';
import { MenuReq, MenuRes } from '@/firebase/dto/get-menu';

export const useMenusStore = defineStore('menus', () => {
  const userStore = useUsersStore();

  // stateはref変数
  const menus = ref({}) as Ref<{ [key: string]: Menu }>;
  const menuImages = ref({}) as Ref<MenuImages>;
  const dbUnsubscriber = ref() as Ref<Unsubscribe | undefined>;

  // gettterはcomputed
  const getMenus = computed(() => menus.value);
  const getMenuImages = computed(() => menuImages.value);
  const getMenuArray = computed(() => {
    return Object.keys(menus.value)
      .map((key) => menus.value[key])
      .sort((m1, m2) => {
        if (m1.params.sort_key > m2.params.sort_key) return 1;
        if (m1.params.sort_key < m2.params.sort_key) return -1;
        return 0;
      });
  });
  const getOptionProductArray = computed(() => {
    return getMenuArray.value
      .filter((elem) => elem.params.division === division.optional)
      .sort((m1, m2) => {
        if (m1.params.sort_key > m2.params.sort_key) return 1;
        if (m1.params.sort_key < m2.params.sort_key) return -1;
        return 0;
      });
  });

  // actionはmutationと統合して、関数
  /**
   * r_menuの監視を開始
   *
   * @param obj
   */
  const startMenusSubscribe = (obj: { contractId: string; liffAccessToken?: string }) => {
    console.log('startSubscribe r_menu_update');

    // メニュー情報をリッスン
    const unsubscriber = onSnapshot(docs.menuUpdateDoc(obj.contractId, userStore.getStoreId), async (doc) => {
      if (doc.exists()) {
        const data: MenuRes = await api.getMenu<MenuReq, MenuRes>({
          process: 'menu',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          liffAccessToken: obj.liffAccessToken,
        });

        menus.value = data.menu.reduce((res, val) => ({ ...res, [val.menuId]: new Menu(val) }), {});

        // 画像ファイルの更新
        await loadMenuImages({
          contractId: obj.contractId,
          menuArray: getMenuArray.value,
        }).catch((error) => {
          console.log(error);
        });
      }
    });
    dbUnsubscriber.value = unsubscriber;
  };

  /**
   * r_menuの監視を停止
   *
   * @param state
   */
  const stopMenusSubscribe = () => {
    console.log('stopSubscribe r_menu_update');
    if (dbUnsubscriber.value) {
      dbUnsubscriber.value();
    }
  };

  /**
   * menuの取得
   *
   * @param obj
   */
  const fetchMenus = async (obj: { contractId: string; liffAccessToken?: string }) => {
    const data: MenuRes = await api.getMenu<MenuReq, MenuRes>({
      process: 'menu',
      contractId: obj.contractId,
      storeId: userStore.getStoreId,
      liffAccessToken: obj.liffAccessToken,
    });

    menus.value = data.menu.reduce((res, val) => ({ ...res, [val.menuId]: new Menu(val) }), {});
  };

  /**
   * メニューの更新
   *
   * @param obj
   */
  const setMenus = async (obj: { menus: RMenu[] }) => {
    menus.value = obj.menus.reduce((res, val) => ({ ...res, [val.menuId]: new Menu(val) }), {});
  };

  /**
   * メニューの追加
   *
   * @param obj
   */
  const addMenu = async (obj: {
    contractId: string;
    menu: RMenu;
    newImage: {
      url: string | null;
      file: File | null;
    };
    smaregi: boolean;
  }) => {
    try {
      // メニューIDの割当
      const newMenuId = obj.smaregi
        ? // スマレジ商品登録（IDを取得）
          await api
            .smaregi<UpdateSmaregiProductReq, UpdateSmaregiProductRes>({
              process: 'update_smaregi_product',
              method: 'add',
              contractId: obj.contractId,
              storeId: userStore.getStoreId,
              menu: obj.menu,
            })
            .then((r) => r.menuId)
        : Utils.makeNewMenuId();

      const updatedMenu: RMenu = {
        ...obj.menu,
        menuId: newMenuId,
        storeId: userStore.getStoreId,
      };

      // 画像の操作
      // menu.imageは変更しない場合はurlのみを持つオブジェクトであり、削除の場合はfileがnullになる。
      if (obj.newImage !== null && obj.newImage.file !== null) {
        // 画像のアップロード
        const imageUrl = Utils.getStorageFilePath({
          is2ndGenData: userStore.getAccountSetting.is2ndGenData,
          folderName: CommonSettings.STORAGE.MENU_IMAGES,
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          fileName: `${newMenuId}.${obj.newImage.file.name.split('.').pop()}`,
        });
        await Utils.setMenuImage({
          url: imageUrl,
          imageFile: obj.newImage.file,
        });
        updatedMenu.image_url = imageUrl; // 更新用オブジェクトにimage_urlをassign
      }

      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: [updatedMenu],
        sort: [],
        cud: 'create',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューの更新
   *
   * @param obj
   */
  const updateMenu = async (obj: {
    contractId: string;
    menuID: string;
    menu: RMenu;
    newImage: {
      url: string | null;
      file: File | null;
    } | null;
    smaregi: boolean;
  }) => {
    try {
      const updatedMenu: RMenu = { ...obj.menu }; // Create a copy of the menu object

      // 画像を更新する場合
      if (obj.newImage?.url && obj.newImage.file) {
        if (updatedMenu.image_url) {
          // 画像の削除
          await Utils.deleteImage({ url: updatedMenu.image_url }).catch((e) => console.error(e));
        }
        // 画像のアップロード
        await Utils.setMenuImage({
          url: obj.newImage.url,
          imageFile: obj.newImage.file,
        }).catch((e) => console.error(e));
        updatedMenu.image_url = obj.newImage.url; // 更新用オブジェクトにimage_urlをassign
      }

      // 画像を削除する場合
      if (updatedMenu.image_url && !obj.newImage?.url && !obj.newImage?.file) {
        // 画像の削除
        await Utils.deleteImage({
          url: updatedMenu.image_url,
        }).catch((e) => console.error(e));

        updatedMenu.image_url = ''; // 更新用オブジェクトにimage_urlをassign
      }
      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: [updatedMenu],
        sort: [],
        cud: 'update',
      });

      // APIが遅い場合に画面の更新が追いつかないので直接書き換えておく
      if (menus.value[obj.menu.menuId]) {
        menus.value[obj.menu.menuId] = new Menu(updatedMenu);
      }

      if (obj.smaregi) {
        await api.smaregi<UpdateSmaregiProductReq, UpdateSmaregiProductRes>({
          process: 'update_smaregi_product',
          method: 'update',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          menu: updatedMenu,
        });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * 在庫状況の変更
   *
   * @param obj
   */
  const updateMenuStatus = async (obj: {
    contractId: string;
    menu: Menu[];
    available?: boolean;
    storeOnly?: boolean;
  }) => {
    try {
      const data = obj.menu.map((val) => {
        const params = val.params;
        return {
          ...params,
          ...(obj.available !== undefined ? { available: obj.available } : {}), // 品切れを更新
          ...(obj.available !== undefined ? { stock: 0 } : {}), // 品切れでも品切れ解除でも残数はリセット
          ...(obj.storeOnly !== undefined ? { storeOnly: obj.storeOnly } : {}), // 非公開を更新
        };
      });

      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: data,
        sort: [],
        cud: 'update',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ポジションの一括更新
   *
   * @param obj
   */
  const bulkUpdateMenuKitchenNo = async (obj: { contractId: string; menus: Menu[]; kitchenNo: string }) => {
    try {
      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: obj.menus.map((val) => ({ ...val.params, positions: [obj.kitchenNo] })),
        sort: [],
        cud: 'update',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューの削除
   *
   * @param obj
   */
  const deleteMenu = async (obj: { contractId: string; menu: Menu; smaregi: boolean }) => {
    try {
      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: [obj.menu.getEntity],
        sort: [],
        cud: 'delete',
      });

      // 画像の削除処理
      if (obj.menu.params.image_url) {
        await Utils.deleteImage({ url: obj.menu.params.image_url });
      }

      // スマレジ商品削除
      if (obj.smaregi) {
        await api.smaregi<UpdateSmaregiProductReq, UpdateSmaregiProductRes>({
          process: 'update_smaregi_product',
          method: 'delete',
          contractId: obj.contractId,
          storeId: userStore.getStoreId,
          menu: obj.menu.params,
        });
      }
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューの削除
   *
   * @param obj
   */
  const deleteMenuBulk = async (obj: { contractId: string; menus: Menu[] }) => {
    try {
      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: obj.menus.map((val) => val.getEntity),
        sort: [],
        cud: 'delete',
      });

      // 画像の一括削除処理
      await Promise.all(
        obj.menus.filter((val) => val.params.image_url).map((val) => Utils.deleteImage({ url: val.params.image_url }))
      );
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューのコピー
   *
   * @param obj
   */
  const copyMenu = async (obj: { contractId: string; menu: Menu; smaregi: boolean }) => {
    try {
      // メニューIDの割当
      const newMenuId = obj.smaregi
        ? // スマレジ商品登録（IDを取得）
          await api
            .smaregi<UpdateSmaregiProductReq, UpdateSmaregiProductRes>({
              process: 'update_smaregi_product',
              method: 'add',
              contractId: obj.contractId,
              storeId: userStore.getStoreId,
              menu: obj.menu.params,
            })
            .then((r) => r.menuId)
        : Utils.makeNewMenuId();

      const copyObj: RMenu = { ...obj.menu.params };

      copyObj.menuId = newMenuId;
      copyObj.image_url = '';
      copyObj.stock = 0;
      copyObj.available = true;

      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: [copyObj],
        sort: [],
        cud: 'create',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニューの並び替え
   *
   * @param obj
   */
  const sortMenu = async (obj: { contractId: string; sortedArray: Menu[] }) => {
    try {
      // キーの配列の長さとカテゴリ数が一致しない場合は失敗
      const params = obj.sortedArray.map((v) => v.getEntity);

      params.forEach((val, index) => {
        val.sort_key = index;
      });

      await api.updateMenu<UpsertMenuReq, UpsertMenuRes>({
        process: 'upsert',
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        menu: [],
        sort: params.map((val) => ({ menuId: val.menuId, sortKey: val.sort_key })),
        cud: 'sort',
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * ブレインPOSからカテゴリー・メニューを取り込み
   *
   * @param obj
   */
  const importMenuFromPos = async (obj: {
    contractId: string;
    pos: 'blayn' | 'smaregi';
  }): Promise<ImportMenuFromPosRes> => {
    try {
      return await api.importMenuFromPos({
        contractId: obj.contractId,
        storeId: userStore.getStoreId,
        pos: obj.pos,
      });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  /**
   * メニュー画像の取得
   *
   * @param obj
   */
  const loadMenuImages = async (obj: { contractId: string; menuArray: Menu[] }) => {
    try {
      const images = {};
      // 画像取得処理の配列を作成
      const tasks = obj.menuArray
        .map((menu) =>
          menu.params.image_url
            ? getDownloadURL(storageRef(Utils.getImagePath(menu.params.image_url)))
                .then((url) => {
                  images[menu.params.menuId] = {
                    url: url,
                  };
                })
                .catch((error) => {
                  // 画像の読み込み失敗はerrorをthrowせずにコンソールに表示する。
                  console.log(error);
                })
            : null
        )
        .filter((f) => f !== null);
      // 画像取得処理
      await Promise.all(tasks)
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          menuImages.value = images;
        });
    } catch (error) {
      throw new StoreError(error.name, error.message);
    }
  };

  return {
    getMenus,
    getMenuImages,
    getMenuArray,
    getOptionProductArray,
    startMenusSubscribe,
    stopMenusSubscribe,
    fetchMenus,
    setMenus,
    addMenu,
    updateMenu,
    updateMenuStatus,
    bulkUpdateMenuKitchenNo,
    deleteMenu,
    deleteMenuBulk,
    copyMenu,
    sortMenu,
    importMenuFromPos,
    loadMenuImages,
  };
});
