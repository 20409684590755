import { Permission } from '@/common/appCode';

export interface Groupe {
  name: string;
  icon: string;
  pages: PageInfo[];
}

export interface PageInfo {
  route: string;
  name: string;
  icon: string;
  requireAuthorization: boolean; // パスワード認証が必要かどうか
  disabled: boolean;
  permission: Permission; // 表示を許可する権限
  is2ndGenMenu?: boolean; // 2ndGenメニューかどうか
  headquartersOnly?: boolean; // 本部のみ
  hiddenHeadquarters?: boolean; // 本部で非表示
  openOtherWindow?: boolean; // 別ウィンドウで開く
}

// ページ情報
export const LIFF_ENTRY_SUCCEED: PageInfo = {
  route: '/entrysucceed',
  name: '入店完了',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const LOGIN: PageInfo = {
  route: '/login',
  name: 'ログイン',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const SELECT_SHOP: PageInfo = {
  route: '/select-store',
  name: '店舗選択',
  icon: 'fact_check',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const HOME: PageInfo = {
  route: '/home',
  name: 'ホーム',
  icon: 'home',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const TABLES: PageInfo = {
  route: '/tables',
  name: '取引一覧',
  icon: 'mdi:mdi-cash-register',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.member,
  hiddenHeadquarters: true,
};

export const ORDER_TICKET_LIST: PageInfo = {
  route: '/order-tickets',
  name: '受注伝票',
  icon: 'assignment',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.member,
  hiddenHeadquarters: true,
};

export const SHIPPING: PageInfo = {
  route: '/shipping',
  name: '発送管理',
  icon: 'local_shipping',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.member,
  hiddenHeadquarters: true,
};

export const TRANSACTION_HISTORY: PageInfo = {
  route: '/transaction-history',
  name: '取引履歴',
  icon: 'history',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  hiddenHeadquarters: true,
};

export const RECEIPT_JOURNAL: PageInfo = {
  route: '/receipt-journal',
  name: '分析',
  icon: 'dvr',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  hiddenHeadquarters: true,
};

export const CUSTOMER_INFO: PageInfo = {
  route: '/customer-info',
  name: '会員管理',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.member,
};

export const WAITING_RECEPTION: PageInfo = {
  route: '/waiting-reception',
  name: '順番待ち受付',
  icon: 'mdi:mdi-human-queue',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.member,
  hiddenHeadquarters: true,
};

export const WAITING_STORE_RECEPTION: PageInfo = {
  route: '/waiting-store-reception',
  name: '順番待ち受付画面',
  icon: 'follow_the_signs',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  openOtherWindow: true,
  hiddenHeadquarters: true,
};

export const WAITING_RECEPTION_STATUS: PageInfo = {
  route: '/waiting-reception-status',
  name: '順番待ち受付状況',
  icon: 'follow_the_signs',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  openOtherWindow: true,
  hiddenHeadquarters: true,
};

export const CUSTOMER_QRCODE_READER: PageInfo = {
  route: '/customer-qrcode-reader',
  name: '会員証リーダー起動',
  icon: 'mdi:mdi-barcode-scan',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  openOtherWindow: true,
  hiddenHeadquarters: true,
};

export const MESSAGE_LIST: PageInfo = {
  route: '/message-list',
  name: 'メッセージ履歴',
  icon: 'textsms',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  hiddenHeadquarters: true,
};

export const MENU: PageInfo = {
  route: '/menu',
  name: '商品編集',
  icon: 'menu_book',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  hiddenHeadquarters: false,
};

export const MEMBERS_RANK: PageInfo = {
  route: '/members-rank',
  name: '会員ランク編集',
  icon: 'hotel_class',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  headquartersOnly: true,
};

export const QUESTIONNAIRE: PageInfo = {
  route: '/questionnaire',
  name: 'アンケート編集',
  icon: 'dynamic_form',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
};

export const MESSAGE_AUTOMATION: PageInfo = {
  route: '/message-automation',
  name: '自動配信設定',
  icon: 'schedule_send',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
};

export const STAFFS: PageInfo = {
  route: '/staffs',
  name: 'スタッフ',
  icon: 'person_apron',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  hiddenHeadquarters: true,
};

export const RESTAURAN_INFO: PageInfo = {
  route: '/store-setting',
  name: '店舗設定',
  icon: 'settings',
  requireAuthorization: true,
  disabled: true,
  permission: Permission.member,
};

export const COMMON_INFO: PageInfo = {
  route: '/common-setting',
  name: '共通設定',
  icon: 'settings',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  headquartersOnly: true,
};

export const STORE_INFO: PageInfo = {
  route: '/store-info',
  name: '店舗編集',
  icon: 'settings',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  is2ndGenMenu: true,
  headquartersOnly: true,
};

export const MEDIA_MANAGED: PageInfo = {
  route: '/media-managed',
  name: 'メディア管理',
  icon: 'upload',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
  hiddenHeadquarters: true,
};

export const COUPONS: PageInfo = {
  route: '/coupons',
  name: 'クーポン管理',
  icon: 'confirmation_number',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.admin,
  headquartersOnly: true,
};

export const CUSTOMER_OPERATION_GROUPE: Groupe = {
  name: 'お客様操作',
  icon: 'swipe',
  pages: [WAITING_STORE_RECEPTION, WAITING_RECEPTION_STATUS, CUSTOMER_QRCODE_READER],
};

export const TRANSACTION_GROUPE: Groupe = {
  name: '取引管理',
  icon: 'mdi:mdi-receipt',
  pages: [TRANSACTION_HISTORY, RECEIPT_JOURNAL],
};

export const CUSTOMER_GROUPE: Groupe = {
  name: '会員',
  icon: 'people',
  pages: [CUSTOMER_INFO, MESSAGE_LIST, MEMBERS_RANK, COUPONS, QUESTIONNAIRE, MESSAGE_AUTOMATION],
};

export const SETTING_GROUPE: Groupe = {
  name: '設定',
  icon: 'settings',
  pages: [STAFFS, COMMON_INFO, RESTAURAN_INFO, STORE_INFO, MEDIA_MANAGED],
};

// グループ
export const groupes: Groupe[] = [TRANSACTION_GROUPE, CUSTOMER_GROUPE, SETTING_GROUPE, CUSTOMER_OPERATION_GROUPE];

// ページの順番
export const pageOrder: PageInfo[] = [HOME, TABLES, ORDER_TICKET_LIST, SHIPPING, WAITING_RECEPTION, MENU];

// イートイン、テイクアウトで使用するページ
export const eatinTakeoutPageOrder: PageInfo[] = [
  TABLES,
  ORDER_TICKET_LIST,
  TRANSACTION_HISTORY,
  CUSTOMER_INFO,
  MESSAGE_LIST,
  RECEIPT_JOURNAL,
  MENU,
  STAFFS,
];

// 通販くんで使用するページ
export const ecPageOrder: PageInfo[] = [SHIPPING, MESSAGE_LIST, TRANSACTION_HISTORY, MENU];

// 順番待ちで使用するページ
export const waitingPageOrder: PageInfo[] = [
  WAITING_RECEPTION,
  RECEIPT_JOURNAL,
  WAITING_STORE_RECEPTION,
  WAITING_RECEPTION_STATUS,
];

// 会員証で使用するページ
export const membershipPageOrder: PageInfo[] = [
  CUSTOMER_INFO,
  MESSAGE_LIST,
  MEMBERS_RANK,
  COUPONS,
  CUSTOMER_QRCODE_READER,
];

// 共通で使用するページ
export const commonPageOrder: PageInfo[] = [
  HOME,
  QUESTIONNAIRE,
  MESSAGE_AUTOMATION,
  COMMON_INFO,
  RESTAURAN_INFO,
  STORE_INFO,
  MEDIA_MANAGED,
];

// ミニアプリ
export const USER_ORDER: PageInfo = {
  route: '/userOrder',
  name: 'テーブルオーダー',
  icon: 'event_note',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const USER_ANYWHERE_ORDER: PageInfo = {
  route: '/userAnywhereOrder',
  name: 'モバイルオーダー',
  icon: 'event_note',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const USER_WAITING: PageInfo = {
  route: '/userWaiting',
  name: '順番待ち',
  icon: 'event_note',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const USER_EC_ORDER: PageInfo = {
  route: '/userEcOrder',
  name: '通販くん',
  icon: 'event_note',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const USER_MEMBERSHIP: PageInfo = {
  route: '/userMembership',
  name: 'メンバーズカード',
  icon: 'event_note',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

// エントリーフォーム
export const ENTRY_FORM: PageInfo = {
  route: '/entryform',
  name: '新規登録',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const PAYMENT_FORM: PageInfo = {
  route: '/paymentform',
  name: '決済情報入力',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};

export const ENTRY_DONE: PageInfo = {
  route: '/entrydone',
  name: '登録完了',
  icon: 'people',
  requireAuthorization: false,
  disabled: true,
  permission: Permission.normal,
};
